<template>
<div class="container">
    <div class="row btm-margin">
        <div class="col-md-3"></div>
        <div class="col-md-6 tp-margin">
            <h1>Login</h1>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <b-form>
                <b-form-group id="input-group-Username" label-cols-sm="3" label="Username:" label-for="Username">
                    <b-form-input id="Username" v-model="$v.form.username.$model" type="text" :state="validateState('username')"></b-form-input>
                    <b-form-invalid-feedback>
                        Username is required
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-Password" label-cols-sm="3" label="Password:" label-for="Password">
                    <b-form-input id="Password" type="password" v-model="$v.form.password.$model" :state="validateState('password')"></b-form-input>
                    <b-form-invalid-feedback>
                        Password is required
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-button variant="dark" style="width:100px;display:block;" class="mx-auto w-100" @click="Login">Login</b-button>
                <div class="mt-2">
                    Do not have an account yet?
                    <router-link to="register"> Register in here</router-link>
                </div>
                <div class="mt-2">
                    Forgot password?
                    <router-link to="forgotPassword">Reset password</router-link>
                </div>
            </b-form>
            <b-alert class="mt-2" v-if="form.submitError" variant="warning" dismissible show>
                Login failed: {{ form.submitError }}
            </b-alert>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>
</template>

<script>

import {
    required
} from "vuelidate/lib/validators";
export default {
    name: "Login",
    data() {
        return {
            form: {
                username: "",
                password: "",
                submitError: undefined
            }
        };
    },
    validations: {
        form: {
            username: {
                required,
            },
            password: {
                required
            }
        }
    },
    methods: {
        validateState(param) {
            const {
                $dirty,
                $error
            } = this.$v.form[param];
            return $dirty ? !$error : null;
        },
        Login() {
            try {
                const content = {
                    username: this.form.username,
                    password: this.form.password
                };
                const json = JSON.stringify(content);
                console.log(this.$store.state.server_domain + "login");

                this.axios.post(this.$store.state.server_domain + "login", json, this.$store.state.config).then((res) => {
                    if (res.data.data.length > 0 && res.data.data != "error") {
                        console.log(res.data.data[1]);
                        this.$store.state.username = res.data.data[0];
                        this.$store.state.permission = res.data.data[1];
                        this.$root.store.login(this.form.username, res.data.data[1]);
                        this.$router.push("/search");
                        this.$root.toast("Login", `Welcome ${this.form.username}`, "success");
                    } else {
                        this.$root.toast("Login", `Login for user ${this.form.username} failed`, "danger");
                    }
                }).catch((err) => {
                    this.$root.toast("Login", `${err.response.data.error}`, "danger");
                });
            } catch (err) {
                this.form.submitError = err.response.data;
                this.$root.toast("Login", `${err.response.data.error}`, "danger");
            }
        },
        onLogin() {
            this.form.submitError = undefined;
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.Login();
        }
    }
};
</script>

<style>
.btm-margin {
    margin-bottom: 3%;
}

.tp-margin {
    margin-top: 3%;
}
</style>
